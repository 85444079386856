export const cubeEvent = (cube, e) => {
    let dataSwitch = e.target.getAttribute('data-switch')
    switch (dataSwitch) {
        case 'switch1':
            cube.style.transform = 'rotateY(0deg) rotateX(0deg)';
            break
        case 'switch2':
            cube.style.transform = 'rotateY(-90deg) rotateX(0deg)';
            break
        case 'switch3':
            cube.style.transform = 'rotateY(180deg) rotateX(0deg)';
            break
        case 'switch4':
            cube.style.transform = 'rotateY(90deg) rotateX(0deg)';
            break
        case 'switch5':
            cube.style.transform = 'rotateY(0deg) rotateX(-90deg)';
            break
        case 'switch6':
            cube.style.transform = 'rotateY(0deg) rotateX(90deg)';
            break
        default:
            cube.style.transform = 'rotateY(0deg) rotateX(0deg)';
    }

    let rotateY = 0, rotateX = 0;

    const start =()=> {
        let setWidth = (document.querySelector('body').offsetWidth) - 300 / 2,
            setHeight = (document.querySelector('body').offsetHeight) - 300 / 2;
        cube.addEventListener('mousemove', (event) => {
            rotateY = event.pageX - setWidth
            rotateX = event.pageY - setHeight
            cube.style.transform =
                'rotateY(' + rotateY + 'deg)' +
                'rotateX(' + rotateX + 'deg)';
        })
    };
    start()
}

