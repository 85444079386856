import "./index.scss"
import {useRef,useEffect} from "react";
import {NavLink} from "react-router-dom";
import {cubeEvent} from "../../_js/cube"
const Header = () => {
    const mw_cube = useRef(null)
    const mw_tabs = useRef(null)

    useEffect(_ => {
        const cube = mw_cube.current
        const tabs = mw_tabs.current
        tabs.onclick =e=> cubeEvent(cube, e)
    },[])

    return (
        <header className="header">
            <h1 className="header-title">Will be here soon</h1>
            <nav className="centered-content">
                <ul ref={mw_tabs} className="tabs">
                    <li><NavLink to="/" className="tabs-title" data-switch="switch1">JS</NavLink></li>
                    <li><NavLink to="/react" className="tabs-title" data-switch="switch2">React</NavLink></li>
                    <li><NavLink to="/redux" className="tabs-title" data-switch="switch3">Redux</NavLink></li>
                    <li><NavLink to="/bootstrap" className="tabs-title" data-switch="switch4">Bootstrap</NavLink></li>
                    <li><NavLink to="/scss" className="tabs-title" data-switch="switch5">SCSS</NavLink></li>
                    <li><NavLink to="/node" className="tabs-title" data-switch="switch6">Node</NavLink></li>
                </ul>
            </nav>
            <div className="box">
                <div className="wrapper">
                    <div ref={mw_cube} className="cube">
                        <div className="side front">JS</div>
                        <div className="side right">React</div>
                        <div className="side back">Redux</div>
                        <div className="side left">Bootstrap</div>
                        <div className="side top">SCSS</div>
                        <div className="side bottom">Node</div>
                    </div>
                </div>
            </div>
        </header>
    )
}
export default Header