import {Route, Routes} from "react-router-dom";
import "./App.scss";
import LayoutHome from "./components/layout/LayoutHome";
import Home from "./components/pages/Home";
import {useEffect, useRef} from "react";
import {runRain} from "./components/_js/rain"

const App = () => {
    const mw_rain = useRef()
    useEffect(_=>{
        const rain = mw_rain.current
        runRain(rain)
    })

    return (
        <>
            <canvas ref={mw_rain}></canvas>
            <div className="App">
                <Routes>
                    <Route path="/" element={<LayoutHome/>}>
                        <Route index element={<Home/>}/>
                        <Route path="/react" element={<Home/>}/>
                        <Route path="/redux" element={<Home/>}/>
                        <Route path="/bootstrap" element={<Home/>}/>
                        <Route path="/scss" element={<Home/>}/>
                        <Route path="/node" element={<Home/>}/>
                    </Route>
                </Routes>
            </div>
        </>
    )
}

export default App
