import {Outlet} from 'react-router-dom'
import Header from "../../pages/Header";

import "./index.scss";

const LayoutHome = () => {
    return (
        <>
            <Header/>
            <Outlet/>
        </>
    )
}
export default LayoutHome